import { userConstants } from '../_constants'
import axios from 'axios'
import { CommonService } from '../_services/common.service'
import * as Sentry from '@sentry/react'
import { hydrateFromStorage } from '../utils/misc/stoage'

export const userActions = {
    login,
    logout,
    changepwd,
    mapStatics,
    clearHeader,
    updateProfile,
    getMe,
}

function login(data: any) {
    return (dispatch: any) => {
        delete data.loginError
        const storedDeviceId: string | null = hydrateFromStorage('deviceId')
        const requestOptions = {
            method: 'post',
            url: `${userConstants.BASE_URL}auth/login`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'device-id': storedDeviceId,
                platform: 'coach',
                'user-type': 'coach',
            },
            data: data,
        }
        return axios(requestOptions)
            .then(function (user) {
                localStorage.setItem('user', JSON.stringify(user.data))
                dispatch(success(user))
                const requestOne = axios.get(
                    `${userConstants.BASE_URL}me/coach`,
                )
                const requestTwo = axios.get(`${userConstants.BASE_URL}statics`)

                axios
                    .all([requestOne, requestTwo])
                    .then(
                        axios.spread((...responses) => {
                            const coachDetails = responses[0]
                            const statics = responses[1].data
                            if (
                                'isPasswordGenerated' in
                                    coachDetails['data']['user'] &&
                                coachDetails.data.user[
                                    'isPasswordGenerated'
                                ] === true
                            ) {
                                localStorage.setItem(
                                    'email',
                                    coachDetails.data.user.email,
                                )
                                setTimeout(function () {
                                    window.location.href = '/change'
                                }, 150)
                            } else {
                                CommonService.getProfilePicture(
                                    coachDetails['data']._id,
                                ).then(() => {
                                    localStorage.setItem(
                                        'statics',
                                        JSON.stringify(statics),
                                    )
                                    localStorage.setItem(
                                        'userinfo',
                                        JSON.stringify(coachDetails.data),
                                    )
                                    dispatch({
                                        type: userConstants.GET_ME_SUCCESS,
                                        payload: coachDetails.data,
                                    })
                                    mapStatics(dispatch)
                                    if (coachDetails.data.user.phoneNumber) {
                                        window.location.replace('/home')
                                    } else {
                                        window.location.replace('/details')
                                    }
                                })
                            }

                            // use/access the results
                        }),
                    )
                    .catch((errors) => {
                        data.loginError =
                            'The details you entered are not recognised'
                    })
            })
            .catch((error) => {
                data.loginError = 'The details you entered are not recognised'
            })
    }
    //function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user: any) {
        return { type: userConstants.LOGIN_SUCCESS, payload: user }
    }
}

function getMe() {
    return axios
        .get(`${userConstants.BASE_URL}me/coach`)
        .then((coach) => {
            localStorage.setItem('userinfo', JSON.stringify(coach.data))
        })
        .catch(function (error) {
            Sentry.captureException(error)
            throw error
        })
}

function changepwd(data: any) {
    const { password, oldPassword } = data
    return (dispatch: any) => {
        const requestOptions = {
            method: 'put',
            url: `${userConstants.BASE_URL}me/password`,
            headers: { 'Content-Type': 'application/json' },
            data: { oldPassword: oldPassword, password: password },
        }
        return axios(requestOptions)
            .then(function (user) {
                const loginData = {
                    email: localStorage.getItem('email'),
                    password: password,
                }
                localStorage.removeItem('email')
                return loginData
            })
            .catch(function (error) {
                Sentry.captureException(error)
                throw error
            })
    }
}
function updateProfile(data: any, editIndividual?: boolean) {
    return (dispatch: any) => {
        let AllStatics = JSON.parse(localStorage.getItem('statics') ?? '{}')
        let selectedLangs = []
        data.nativeLanguages.forEach((item: any) => {
            AllStatics.languages.forEach((item2: any) => {
                if (item2.name === item) {
                    selectedLangs.push(item2.key)
                }
            })
        })

        let selectedCountry = ''
        AllStatics.countries.forEach((item2: any) => {
            if (item2.name === data.country) {
                selectedCountry = item2.key
            }
        })

        if (!data.phoneNumber?.startsWith('+')) {
            data.phoneNumber = '+' + data.phoneNumber
        }

        let obj: Coach = {
            user: {
                address: {
                    country: '',
                },
            },
            profileInformation: {},
        } as Coach

        // {
        //     user: {
        //         address: {
        //             country: '',
        //         },
        //     },
        // }

        // if (data.bio) {
        //     obj.bio = { bio: data.bio, language: 'en' }
        // }

        if (data.firstName) {
            obj.user.firstName = data.firstName
        }
        if (data.lastName) {
            obj.user.lastName = data.lastName
        }
        if (data.birthday) {
            let date = new Date(data.birthday)
            obj.user.birthday = date.toISOString()
        }
        if (data.nativeLanguages) {
            obj.user.nativeLanguages = mapStaticsArray(
                'nativeLanguages',
                'languages',
                data,
            )
        }
        if (data.phoneNumber) {
            obj.user.phoneNumber = data.phoneNumber
        }
        if (data.country) {
            obj.user.address = {
                country: mapStaticsString('country', 'countries', data),
                city: data.city ?? '',
            }
        }

        if (data.bio) {
            obj.bio = data.bio
        }

        if (data.startCoachingYear) {
            obj.startCoachingYear = data.startCoachingYear
        }
        if (data.areasCompetencies) {
            obj.areasCompetencies = mapStaticsArray(
                'areasCompetencies',
                'competencies',
                data,
            )
        }

        if (data.coachingSpecialitiesTopics) {
            obj.profileInformation.coachingSpecialitiesTopics = mapStaticsArray(
                'coachingSpecialitiesTopics',
                'coachingSpecialitiesTopics',
                data,
            )
        }

        if (data.coachingSpecialitiesClient) {
            obj.profileInformation.coachingSpecialitiesClient = mapStaticsArray(
                'coachingSpecialitiesClient',
                'coachingSpecialitiesClient',
                data,
            )
        }

        if (data.coachingExperience) {
            obj.profileInformation.coachingExperience = mapStaticsString(
                'coachingExperience',
                'teamCoachingExperience',
                data,
            )
        }

        if (data.coachingCredentials) {
            obj.profileInformation.coachingCredentials = mapStaticsString(
                'coachingCredentials',
                'teamCoachingCredential',
                data,
            )
        }

        if (data.coachingCertifications) {
            obj.profileInformation.coachingCertifications = mapStaticsArray(
                'coachingCertifications',
                'assessmentCertifications',
                data,
            )
        }

        if (data.coachingMentoring) {
            obj.profileInformation.coachingMentoring = mapStaticsArray(
                'coachingMentoring',
                'coachingMentoring',
                data,
            )
        }

        if (data.trainingTopics) {
            obj.profileInformation.trainingTopics = mapStaticsArray(
                'trainingTopics',
                'trainingTopics',
                data,
            )
        }

        if (data.interestedInCollaboration) {
            obj.profileInformation.interestedInCollaboration =
                data.interestedInCollaboration
        }

        if (data.coachCollaborationAreaOfInterest) {
            obj.profileInformation.coachCollaborationAreaOfInterest =
                data.coachCollaborationAreaOfInterest
        }

        if (data.profIndexp) {
            obj.experienceIndustries = mapStaticsArray(
                'profIndexp',
                'industries',
                data,
            )
        }
        if (data.profFunexp) {
            obj.experienceFunctions = mapStaticsArray(
                'profFunexp',
                'functions',
                data,
            )
        }
        if (data.experienceCountry) {
            obj.experienceCountry = mapStaticsArray(
                'experienceCountry',
                'countries',
                data,
            )
        }
        if (data.coachingAccreditations) {
            obj.coachingAccreditations = mapStaticsArray(
                'coachingAccreditations',
                'accreditations',
                data,
            )
        }
        if (data.coachingLanguages) {
            obj.coachingLanguages = mapStaticsArray(
                'coachingLanguages',
                'languages',
                data,
            )
        }
        if (data.coachingMemberships) {
            obj.coachingMemberships = mapStaticsArray(
                'coachingMemberships',
                'memberships',
                data,
            )
        }
        if (data.coachingIndustries) {
            obj.coachingIndustries = mapStaticsArray(
                'coachingIndustries',
                'industries',
                data,
            )
        }
        if (data.coachingFunctions) {
            obj.coachingFunctions = mapStaticsArray(
                'coachingFunctions',
                'functions',
                data,
            )
        }
        if (data.experienceTotalYear) {
            obj.experienceTotalYear = data.experienceTotalYear
        }
        if (data.gender) {
            obj.gender = data.gender
        }
        if (data.highestEducationLevel) {
            obj.highestEducationLevel = mapStaticsString(
                'highestEducationLevel',
                'educationLevels',
                data,
            )
        }
        if (data.maximumHourPerWeek) {
            obj.maximumHourPerWeek = data.maximumHourPerWeek
        }

        if (data.experienceYearIn) {
            obj.experienceYearIn = data.experienceYearIn
        }

        const requestOptions = {
            method: 'put',
            url: `${userConstants.BASE_URL}me/coach/`,
            headers: { 'Content-Type': 'application/json' },
            data: obj,
        }

        axios(requestOptions)
            .then(function (res) {
                axios
                    .get(`${userConstants.BASE_URL}me/coach`)
                    .then(function (res) {
                        localStorage.setItem(
                            'userinfo',
                            JSON.stringify(res.data),
                        )
                        dispatch({
                            type: userConstants.GET_ME_SUCCESS,
                            payload: res.data,
                        })
                        if (editIndividual) {
                            window.location.href = '/profile'
                        }
                    })
                    .catch(function (error) {
                        Sentry.captureException(error)
                        // console.log("Error: ", error);
                    })
            })
            .catch(function (error) {
                Sentry.captureException(error)
                // console.log("Error: ", error);
            })
    }
}

function mapStaticsArray(field: string, staticField: string, data: any) {
    let returnData: string[] = []
    if (data[field]) {
        const AllStatics = JSON.parse(localStorage.getItem('statics') ?? '{}')
        data[field].forEach((item: string) => {
            AllStatics[staticField].forEach((item2: StaticsValues) => {
                if (item2.name === item) {
                    returnData.push(item2.key)
                }
            })
        })
    }
    return returnData
}
function mapStaticsString(field: string, staticField: string, data: any) {
    const AllStatics = JSON.parse(localStorage.getItem('statics') ?? '{}')
    let returnData = ''
    AllStatics[staticField].forEach((item2: StaticsValues) => {
        if (item2.name === data[field]) {
            returnData = item2.key
        }
    })
    return returnData
}

function logout() {
    localStorage.clear()
    return { type: userConstants.LOGOUT }
}

function mapStatics(dispatch: any) {
    const userInfo = localStorage.getItem('userinfo')
    const stats = localStorage.getItem('statics')
    let AllStatics = JSON.parse(stats ?? '{}') as Statics
    if (stats && userInfo) {
        let data = Object.create({})
        for (let key in AllStatics) {
            // @ts-ignore
            data[key] = AllStatics[key as keyof typeof AllStatics].map(
                (item: StaticsValues) => item.name,
            )
        }
        let selectedLangs: string[] = []
        let coach = JSON.parse(userInfo ?? '{}') as Coach
        coach.user.nativeLanguages.forEach((item) => {
            AllStatics.languages.forEach((item2) => {
                if (item2.key === item) {
                    selectedLangs.push(item2.name)
                }
            })
        })
        const selectedCoachLangs: string[] = []
        coach.coachingLanguages.forEach((item) => {
            AllStatics.languages.forEach((item2) => {
                if (item2.key === item) {
                    selectedCoachLangs.push(item2.name)
                }
            })
        })
        let highestEducationLevel = ''
        AllStatics.educationLevels.forEach((item2) => {
            if (item2.key === coach.highestEducationLevel) {
                highestEducationLevel = item2.name
            }
        })
        let selectedCountry = ''
        AllStatics.countries.forEach((item2) => {
            if (item2.key === coach.user?.address?.country) {
                selectedCountry = item2.name
            }
        })
        const experienceFunctions: string[] = []
        coach.experienceFunctions.forEach((item) => {
            AllStatics.functions.forEach((item2) => {
                if (item2.key === item) {
                    experienceFunctions.push(item2.name)
                }
            })
        })
        let coachingExperience = ''
        AllStatics?.teamCoachingExperience?.forEach((item2) => {
            if (item2.key === coach?.profileInformation?.coachingExperience) {
                coachingExperience = item2.name
            }
        })
        let coachingCredentials = ''
        const credentialKey = coach?.profileInformation?.coachingCredentials
        if (credentialKey) {
            coachingCredentials =
                AllStatics?.teamCoachingCredential?.find(
                    (item2) => item2.key === credentialKey,
                )?.name || ''
        }
        const interestedInCollaboration =
            coach?.profileInformation?.interestedInCollaboration ?? ''
        const experienceFunctionsCoaching: string[] = []
        coach.coachingFunctions.forEach((item) => {
            AllStatics.functions.forEach((item2) => {
                if (item2.key === item) {
                    experienceFunctionsCoaching.push(item2.name)
                }
            })
        })

        const experienceIndustries: string[] = []
        coach.experienceIndustries.forEach((item) => {
            AllStatics.industries.forEach((item2) => {
                if (item2.key === item) {
                    experienceIndustries.push(item2.name)
                }
            })
        })

        const experienceIndustriesCoaching: string[] = []
        coach.coachingIndustries.forEach((item) => {
            AllStatics.industries.forEach((item2) => {
                if (item2.key === item) {
                    experienceIndustriesCoaching.push(item2.name)
                }
            })
        })
        const experienceCountry: string[] = []
        coach.experienceCountry.forEach((item) => {
            AllStatics.countries.forEach((item2) => {
                if (item2.key === item) {
                    experienceCountry.push(item2.name)
                }
            })
        })
        const areasCompetencies: string[] = []
        coach.areasCompetencies.forEach((item) => {
            AllStatics.competencies.forEach((item2) => {
                if (item2.key === item) {
                    areasCompetencies.push(item2.name)
                }
            })
        })
        const coachingAccreditations: string[] = []
        coach.coachingAccreditations.forEach((item) => {
            AllStatics.accreditations.forEach((item2) => {
                if (item2.key === item) {
                    coachingAccreditations.push(item2.name)
                }
            })
        })
        const coachingMemberships: string[] = []
        coach.coachingMemberships.forEach((item) => {
            AllStatics.memberships.forEach((item2) => {
                if (item2.key === item) {
                    coachingMemberships.push(item2.name)
                }
            })
        })
        const coachingIndustries: string[] = []
        coach.coachingIndustries.forEach((item) => {
            AllStatics.industries.forEach((item2) => {
                if (item2.key === item) {
                    coachingIndustries.push(item2.name)
                }
            })
        })
        const coachingFunctions: string[] = []
        coach.coachingFunctions.forEach((item) => {
            AllStatics.functions.forEach((item2) => {
                if (item2.key === item) {
                    coachingFunctions.push(item2.name)
                }
            })
        })
        AllStatics = {
            ...AllStatics,
            statics: data,
            selectedLangs,
            coachingCredentials,
            coachingExperience,
            selectedCoachLangs,
            highestEducationLevel,
            selectedCountry,
            experienceIndustries,
            experienceIndustriesCoaching,
            experienceFunctions,
            experienceFunctionsCoaching,
            experienceCountry,
            areasCompetencies,
            coachingAccreditations,
            coachingMemberships,
            coachingIndustries,
            interestedInCollaboration,
            coachingFunctions,
        }
        dispatch({
            type: userConstants.GET_STATICS,
            payload: AllStatics,
        })
    }
}
function clearHeader() {
    localStorage.removeItem('userinfo')
    return { type: userConstants.GET_ME_LOGOUT }
}
