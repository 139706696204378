import { DocumentRenderer } from '@sama-io/react-doc-viewer'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
//@ts-ignore
import { userConstants } from '../_constants'
import { getStaticsValue } from '../_helpers/statics'
import { default as CanInviteIcon } from '../assets/icons/can-invite_icon.svg'
import CloseIcon from '../assets/icons/close_icon_white.svg'
import fileIcon from '../assets/icons/file.svg'
import { formatDate } from '../misc/dates'
import { dataURItoBlobString } from '../misc/images'
import { Coachee } from '../types/coachee.t'
import { FileDownload } from '../types/file-download.t'
import { useStores } from '../utils/stores'
import { Booking } from './types/Booking'

const ProfilePopPage = ({
    coachee,
    fullName,
    dispatch,
    setOpenProfile,
    openProfile,
}: {
    coachee: Coachee | Booking['fromCoachee']
    fullName?: any
    dispatch: any
    setOpenProfile: any
    openProfile: any
}) => {
    const { coach } = useStores()

    const [fullCoachee, setCoachee] = useState(null as Coachee | null)
    const [coacheeFullName, setCoacheeFullName] = useState('')
    const [image, setImage] = useState<string | null>(null)
    const [files, setFiles] = useState([] as FileDownload[])
    const [companyFiles, setCompanyFiles] = useState([] as FileDownload[])
    const [departmentFiles, setDepartmentFiles] = useState([] as FileDownload[])
    const [fileToOpen, setFileToOpen] = useState(null as FileDownload | null)
    const [isFileOpen, openFile] = useState(false)
    const [canAddGuests, setCanAddGuests] = useState(false)
    const personalValues = localStorage.getItem('statics')
        ? JSON.parse(localStorage.getItem('statics') as string).personalValues
        : {}
    const id = localStorage.getItem('userinfo')
        ? JSON.parse(localStorage.getItem('userinfo') as string)._id.toString()
        : null

    useEffect(() => {
        if (openProfile) {
            axios
                .get(`${userConstants.BASE_URL}coachee/${coachee._id}`)
                .then((data) => {
                    dispatch({
                        type: userConstants.GET_COACHEE_PROFILE,
                        payload: data.data,
                    })
                    setCoachee(data.data)

                    coach.getCoacheeProfilePicture(coachee._id).subscribe({
                        next(response: any) {
                            if (response) {
                                setImage(dataURItoBlobString(response))
                            }
                        },
                    })

                    if (data.data.user.company.settings) {
                        setCanAddGuests(
                            data.data.user.company.settings.sessionGuests
                                .canInvite,
                        )
                    }

                    setCoacheeFullName(coachee.fullName ?? fullName)
                })
            getFiles()
            getCompanyFiles()
            getDepartmentFiles()
        }
    }, [openProfile])

    const openFileView = useCallback(
        (file: FileDownload) => {
            openFile(true)
            console.log(file)
            setFileToOpen(file)
        },
        [isFileOpen],
    )

    const getFiles = useCallback(() => {
        return axios
            .get(
                `${userConstants.BASE_URL}coachee/${coachee._id}/insight-files`,
            )
            .then((data) => {
                setFiles(data.data)
            })
    }, [openProfile])

    const getCompanyFiles = useCallback(() => {
        if (coachee?.user?.company || coachee.company) {
            return axios
                .get(
                    `${userConstants.BASE_URL}company/${
                        coachee?.user?.company._id ?? coachee.company._id
                    }/insight-files`,
                )
                .then((data) => {
                    setCompanyFiles(data.data)
                })
        }

        return
    }, [openProfile])

    const getDepartmentFiles = useCallback(() => {
        if (coachee.department) {
            return axios
                .get(
                    `${userConstants.BASE_URL}department/${
                        coachee?.user?.company._id ?? coachee.company._id
                    }/${coachee.department}/insight-files`,
                )
                .then((data) => {
                    setDepartmentFiles(data.data)
                })
        }

        return
    }, [openProfile])

    return (
        <div>
            <p className="pointer" onClick={() => setOpenProfile(true)}>
                See profile
            </p>

            {fullCoachee && (
                <Popup
                    open={openProfile}
                    onClose={() => setOpenProfile(false)}
                    modal
                    nested
                    className="aside"
                    lockScroll
                >
                    <div className="modal mdsdisp user-profile-info">
                        <div className="flex items-center justify-center bg-[#004e72] py-3">
                            <div className="flex-1"></div>
                            <div className="text-white">Profile</div>
                            <div className="flex-1 mr-4">
                                <div className="w-5 ml-auto">
                                    <button
                                        className="w-5 h-5 ml-auto"
                                        onClick={() => setOpenProfile(false)}
                                    >
                                        <img src={CloseIcon} />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="content">
                            <div className="modal-body">
                                <div className="flex items-center justify-center">
                                    <div className="">
                                        {!image && (
                                            <span className="fa fa-camera"></span>
                                        )}
                                        {image && (
                                            <img
                                                className="thumbnail-large w-full h-full"
                                                src={image}
                                                alt="logo"
                                            />
                                        )}
                                    </div>
                                    <div className="flex-1 w-full">
                                        <div className="grid justify-start">
                                            <p className="font-bold text-lg">
                                                {coacheeFullName}
                                            </p>

                                            <div className="flex items-center justify-start mt-2">
                                                <div className="flex items-center justify-center w-4 h-4 rounded-full bg-[#ff7558] mr-2 p-2">
                                                    <span className="flex font-light text-white text-sm mb-[1px]">
                                                        #
                                                    </span>
                                                </div>
                                                <span className="mb-1">
                                                    {fullCoachee.coacheeMinutesRemaining &&
                                                        fullCoachee.coacheeMinutesRemaining >=
                                                            0 && (
                                                            <>
                                                                <Popup
                                                                    trigger={() => {
                                                                        return (
                                                                            <div className="flex justify-center items-center cursor-pointer">
                                                                                {fullCoachee.coacheeMinutesRemaining &&
                                                                                    fullCoachee.coacheeMinutesRemaining /
                                                                                        25}{' '}
                                                                                sessions
                                                                                remaining
                                                                            </div>
                                                                        )
                                                                    }}
                                                                    modal
                                                                    repositionOnResize
                                                                    className="popup-text-box"
                                                                    nested
                                                                    lockScroll
                                                                >
                                                                    <>
                                                                        <h6 className="semi-underline mt-4">
                                                                            How
                                                                            does
                                                                            Sama
                                                                            calculate
                                                                            a
                                                                            session?
                                                                        </h6>
                                                                        <br />
                                                                        <p>
                                                                            A
                                                                            session
                                                                            equals
                                                                            25
                                                                            minutes.
                                                                            A
                                                                            coaching
                                                                            session
                                                                            of
                                                                            50
                                                                            minutes
                                                                            equates
                                                                            to
                                                                            two
                                                                            sessions
                                                                        </p>
                                                                    </>
                                                                </Popup>
                                                            </>
                                                        )}
                                                    {!fullCoachee.coacheeMinutesRemaining && (
                                                        <>Unlimited hours</>
                                                    )}
                                                </span>
                                            </div>

                                            {canAddGuests && (
                                                <div className="flex items-center justify-start py-2">
                                                    <img
                                                        src={CanInviteIcon}
                                                        className="w-4 h-4 mr-2"
                                                    />
                                                    <p className="text-center">
                                                        Can add colleagues to
                                                        session
                                                    </p>
                                                </div>
                                            )}

                                            <div className="flex items-center justify-start py-2">
                                                <img
                                                    src={CanInviteIcon}
                                                    className="w-4 h-4 mr-2"
                                                />
                                                <p className="text-center">
                                                    Access until:{' '}
                                                    {fullCoachee.accessUntil
                                                        ? formatDate(
                                                              new Date(
                                                                  fullCoachee.accessUntil,
                                                              ),
                                                          )
                                                        : 'Ongoing'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center mt-2">
                                    {fullCoachee.status === 'paused' && (
                                        <div className="flex justify-center items-center">
                                            <div className="flex border-2 border-dashed rounded-3xl py-2 px-3">
                                                <i className="i-icon-2"></i>
                                                <p>This coachee is paused</p>
                                            </div>
                                        </div>
                                    )}
                                    {fullCoachee.canPause && (
                                        <div className="py-3">
                                            <a
                                                href={
                                                    (fullCoachee.sessionsDone !==
                                                    0
                                                        ? import.meta.env
                                                              .VITE_APP_PAUSE_COACHEE_REQUEST_LINK
                                                        : import.meta.env
                                                              .VITE_APP_PAUSE_COACHEE_NO_SESSION_REQUEST_LINK) +
                                                    `#coach_id=${encodeURIComponent(
                                                        id,
                                                    )}&coachee_id=${encodeURIComponent(
                                                        fullCoachee._id,
                                                    )}`
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="bg-[#ffd4c0] text-[#ff7558] text-[15px] bg-opacity-25 rounded-3xl py-3 px-4"
                                            >
                                                Pause coachee
                                            </a>
                                        </div>
                                    )}
                                </div>

                                {fullCoachee.confidentialInsight && (
                                    <Popup
                                        trigger={() => {
                                            return (
                                                <table className="table-bordered profile-menu mt-3">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <i className="confidential-insights-icon"></i>
                                                                <span>
                                                                    User
                                                                    information
                                                                </span>
                                                                <i className="profile-menu-arrow-icon"></i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )
                                        }}
                                        modal
                                        repositionOnResize
                                        className="popup-text-box"
                                        nested
                                        lockScroll
                                    >
                                        <>
                                            <h6 className="semi-underline mt-4">
                                                User Information
                                            </h6>
                                            <br />
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: fullCoachee.confidentialInsight,
                                                }}
                                            ></div>
                                        </>
                                    </Popup>
                                )}

                                {fullCoachee.companyConfidentialInsight && (
                                    <Popup
                                        trigger={() => {
                                            return (
                                                <table className="table-bordered profile-menu">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <i className="confidential-insights-icon"></i>
                                                                <span>
                                                                    Company
                                                                    Insights
                                                                </span>
                                                                <i className="profile-menu-arrow-icon"></i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            )
                                        }}
                                        modal
                                        repositionOnResize
                                        className="popup-text-box"
                                    >
                                        <>
                                            <h6 className="semi-underline mt-4">
                                                Company insights
                                            </h6>
                                            <br />
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: fullCoachee.companyConfidentialInsight,
                                                }}
                                            ></div>
                                        </>
                                    </Popup>
                                )}

                                {fileToOpen && (
                                    <>
                                        <DocumentRenderer
                                            isOpen={isFileOpen}
                                            setIsOpen={() => openFile(false)}
                                            file={fileToOpen}
                                            overlayClassName="z-1000"
                                        />
                                    </>
                                )}

                                {files.map((file, index) => (
                                    <div
                                        style={{
                                            paddingTop: '7px',
                                            cursor: 'pointer',
                                            display: 'inline-flex',
                                            width: '100%',
                                        }}
                                        key={index}
                                    >
                                        <img
                                            style={{
                                                height: '30px',
                                            }}
                                            src={fileIcon}
                                        />
                                        <div
                                            style={{
                                                paddingTop: '0px',
                                                paddingRight: '5px',
                                                cursor: 'pointer',
                                                width: '90%',
                                                marginLeft: '10px',
                                            }}
                                            onClick={() => openFileView(file)}
                                        >
                                            <p
                                                style={{
                                                    marginTop: '6px',
                                                    lineHeight: 1,
                                                    margin: 0,
                                                    fontWeight: 600,
                                                    overflow: 'hidden',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {file.name}
                                            </p>
                                            <p
                                                style={{
                                                    lineHeight: 1,
                                                    fontSize: '10px',
                                                }}
                                            >
                                                {formatDate(
                                                    new Date(file.createdAt),
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                ))}

                                {companyFiles.map((cFile, index) => (
                                    <div
                                        style={{
                                            paddingTop: '7px',
                                            cursor: 'pointer',
                                            display: 'inline-flex',
                                            width: '100%',
                                        }}
                                        key={index}
                                    >
                                        <img
                                            style={{
                                                height: '30px',
                                            }}
                                            src={fileIcon}
                                        />
                                        <div
                                            style={{
                                                paddingTop: '0px',
                                                paddingRight: '5px',
                                                cursor: 'pointer',
                                                width: '90%',
                                                marginLeft: '10px',
                                            }}
                                            onClick={() => openFileView(cFile)}
                                        >
                                            <p
                                                style={{
                                                    marginTop: '6px',
                                                    lineHeight: 1,
                                                    margin: 0,
                                                    fontWeight: 600,
                                                    overflow: 'hidden',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {cFile.name}
                                            </p>
                                            <p
                                                style={{
                                                    lineHeight: 1,
                                                    fontSize: '10px',
                                                }}
                                            >
                                                {formatDate(
                                                    new Date(cFile.createdAt),
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                ))}

                                {departmentFiles.map((dFile, index) => (
                                    <div
                                        style={{
                                            paddingTop: '7px',
                                            cursor: 'pointer',
                                            display: 'inline-flex',
                                            width: '100%',
                                        }}
                                        key={index}
                                    >
                                        <img
                                            style={{
                                                height: '30px',
                                            }}
                                            src={fileIcon}
                                        />
                                        <div
                                            style={{
                                                paddingTop: '0px',
                                                paddingRight: '5px',
                                                cursor: 'pointer',
                                                width: '90%',
                                                marginLeft: '10px',
                                            }}
                                            onClick={() => openFileView(dFile)}
                                        >
                                            <p
                                                style={{
                                                    marginTop: '6px',
                                                    lineHeight: 1,
                                                    margin: 0,
                                                    fontWeight: 600,
                                                    overflow: 'hidden',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {dFile.name}
                                            </p>
                                            <p
                                                style={{
                                                    lineHeight: 1,
                                                    fontSize: '10px',
                                                }}
                                            >
                                                {formatDate(
                                                    new Date(dFile.createdAt),
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                ))}

                                <h6 className="semi-underline mt-4">
                                    Personal information
                                </h6>
                                <table className="mt-3 shadow-sm p-3 rounded-md">
                                    <tbody>
                                        {fullCoachee?.coachingLanguage && (
                                            <tr className="border-b-[1px] border-gray-200 px-[20px]">
                                                <td className="text-gray-500 font-light">
                                                    Coaching language
                                                </td>
                                                <td className="text-gray-700">
                                                    {
                                                        fullCoachee?.coachingLanguage
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                        <tr className="border-b-[1px] border-gray-200 px-[20px]">
                                            <td className="text-gray-500 font-light">
                                                Position
                                            </td>
                                            <td className="text-gray-700">
                                                {fullCoachee?.position}
                                            </td>
                                        </tr>
                                        <tr className="border-b-[1px] border-gray-200 px-[20px]">
                                            <td className="text-gray-500 font-light">
                                                Industry
                                            </td>
                                            <td className="text-gray-700">
                                                {fullCoachee?.industry}
                                            </td>
                                        </tr>
                                        <tr className="border-b-[1px] border-gray-200 px-[20px]">
                                            <td className="text-gray-500 font-light">
                                                Function
                                            </td>
                                            <td className="text-gray-700">
                                                {fullCoachee?.function}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-gray-500 font-light">
                                                Timezone
                                            </td>
                                            <td className="text-gray-700">
                                                {fullCoachee?.timezone}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h6 className="semi-underline mt-4">
                                    Coaching preferences{' '}
                                </h6>
                                <div className="mt-3 shadow-sm p-3 rounded-md">
                                    <div>
                                        <span className="font-light">
                                            Area of coaching
                                        </span>
                                        <div className="font-semibold">
                                            {fullCoachee?.desireCompetency}
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <span className="font-light">
                                            Coach gender preferences
                                        </span>
                                        <div className="font-semibold">
                                            {fullCoachee?.coachGender ==
                                            'Everything'
                                                ? "I don't mind"
                                                : fullCoachee?.coachGender}
                                        </div>
                                    </div>
                                </div>

                                <h6 className="semi-underline mt-4">
                                    Overall wellbeing
                                </h6>
                                <div className="mt-3 shadow-sm p-3 rounded-md">
                                    <div className="pb-2">
                                        {personalValues.map(
                                            (
                                                value: {
                                                    [key: string]: string
                                                },
                                                index: number,
                                            ) => (
                                                <div
                                                    className="flex justify-between items-center mb-2"
                                                    key={index}
                                                >
                                                    <span className="font-normal text-gray-700">
                                                        {value.name}
                                                    </span>
                                                    <span className="font-bold text-gray-800">
                                                        {fullCoachee.personalValues &&
                                                        fullCoachee
                                                            ?.personalValues[
                                                            value.key
                                                        ]
                                                            ? `${
                                                                  fullCoachee
                                                                      ?.personalValues[
                                                                      value.key
                                                                  ]
                                                              }/5 `
                                                            : 'N/A'}
                                                    </span>
                                                </div>
                                            ),
                                        )}
                                    </div>
                                </div>

                                <h6 className="semi-underline mt-4">
                                    Top 5 Values
                                </h6>
                                <div className="mt-3 shadow-sm p-3 rounded-md">
                                    {fullCoachee.topValues &&
                                    fullCoachee.topValues.length !== 0 ? (
                                        fullCoachee.topValues.map(
                                            (value, i) => (
                                                <div
                                                    className="rounded-full bg-[#f4f5f8] inline-block mb-2 mr-2"
                                                    key={i}
                                                >
                                                    <p className="py-2 px-4">
                                                        {getStaticsValue(
                                                            'topValues',
                                                            value,
                                                        )}
                                                    </p>
                                                </div>
                                            ),
                                        )
                                    ) : (
                                        <p>No values selected</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
            )}
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    coach: state.userreducer.userinfo,
    picture: state.picture.picture,
    statics: state.statics,
})

export default connect(mapStateToProps)(ProfilePopPage)
