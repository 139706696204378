import { addDays, format } from 'date-fns'
import subDays from 'date-fns/subDays'
import { useEffect, useState } from 'react'
import { Calendar } from 'react-calendar'
import { CommonService } from '../_services/common.service'
import calendarIcon from '../assets/icons/calendar-filter.svg'
import checkIcon from '../assets/icons/check.svg'
import upcomingIcon from '../assets/icons/upcoming.svg'
import chevronIcon from '../assets/icons/chevron-down.svg'
import { formatDayDate } from '../misc/dates'
import CoachNoteView from './coach-note-view'

interface ISessionProps {
    coacheeId: string
    sessionsData?: any[]
    coacheeName: string
}

export default function SessionHistory({
    coacheeId,
    coacheeName,
}: ISessionProps) {
    const [sessionsData, setSessionsData] = useState([])
    const [currentItem, setCurrentItem] = useState<any>()
    const [isCoachNoteViewOpen, openCoachNoteView] = useState(false)
    const [isFilterOn, setIsFilterOn] = useState(false)
    const [datesFilter, setDatesFilter] = useState<{
        startDate: Date | null
        endDate: Date | null
    }>({
        startDate: null,
        endDate: null,
    })
    const [dates, setDates] = useState<Date[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

    useEffect(() => {
        loadSessionHistory()
    }, [coacheeId, datesFilter])

    function openNote(item: any) {
        setCurrentItem(item)
        openCoachNoteView(true)
    }

    useEffect(() => {
        //if calendar is open and two dates are selected, then filter by dates
        if (isDatePickerOpen && dates.length === 2 && isFilterOn) {
            setIsDatePickerOpen(false)

            setDatesFilter({
                startDate: dates[0],
                endDate: dates[1],
            })

            setDates([])
        }
    }, [dates])

    function isDateSelected(datesRange: Date[], value: Date) {
        return !!datesRange.find((item) => {
            return item.getTime() === value.getTime()
        })
    }

    function onClickDay(date: Date) {
        //select the first two days clicked
        if (!isDateSelected(dates, date) && dates.length < 2) {
            const sortedDates = [...dates, date].sort((a: Date, b: Date) => {
                return a.getTime() - b.getTime()
            })
            setDates(sortedDates)
            setIsFilterOn(true)
        }
    }

    function toggleDatePicker() {
        setIsDatePickerOpen(!isDatePickerOpen)
    }

    async function loadSessionHistory() {
        let object = {
            startDate: datesFilter.startDate,
            endDate: datesFilter.endDate,
        }
        setIsLoading(true)
        CommonService.getCoacheeSessions(
            coacheeId,
            object,
            function (res: any) {
                if (!res.data.empty) {
                    res.data.reverse()
                    setSessionsData(res.data)
                    setIsLoading(false)
                } else {
                    setSessionsData([])
                }
            },
        )
    }
    const handleSave = () => {
        loadSessionHistory()
        openCoachNoteView(false)
    }

    return (
        <div id="sessions" className="tab-pane container active">
            <div className="flex items-center justify-between">
                <div>
                    <h6 className="semi-underline pt-8">
                        <div className="font-bold mb-8">Session history</div>
                    </h6>
                </div>

                <div>
                    <button
                        onClick={toggleDatePicker}
                        className={`flex border-[1px] border-gray-300 py-2 px-4 items-center justify-evenly rounded-full h-[36px] gap-2  hover:border-[#004e72] ${
                            isFilterOn ? 'text-[#ff7d48]' : 'text-[#004e72]'
                        }`}
                    >
                        <img src={calendarIcon} className="h-5 w-5" />
                        <div>
                            {datesFilter.startDate === null
                                ? format(subDays(new Date(), 7), 'MMM dd yyyy')
                                : format(datesFilter.startDate, 'MMM dd yyyy')}
                            {' - '}
                            {datesFilter.endDate === null
                                ? format(new Date(), 'MMM dd yyyy')
                                : format(datesFilter.endDate, 'MMM dd yyyy')}
                        </div>
                        <img src={chevronIcon} className="h-2 w-2" />
                    </button>

                    {isDatePickerOpen && (
                        <div className="flex absolute pt-2 -ml-32">
                            <Calendar
                                value={[
                                    datesFilter.startDate,
                                    addDays(new Date(datesFilter.endDate!), 1),
                                ]}
                                onClickDay={onClickDay}
                            />
                        </div>
                    )}
                </div>
            </div>
            {isLoading ? (
                'Loading sessions...'
            ) : (
                <div>
                    {sessionsData?.map((session: any) => {
                        const hasNote =
                            session.state !== 'cancel' && session.note
                        return (
                            <div
                                key={session._id}
                                className={`flex items-start justify-between note-box py-4 rounded-md my-2 px-4`}
                            >
                                <div
                                    className={`font-bold mt-2 text-white rounded-full ${
                                        session.state === 'cancel'
                                            ? `bg-[#ff5353]`
                                            : session.state === 'close'
                                            ? `bg-[#008aa6] ]`
                                            : session.state === 'planned'
                                            ? `bg-transparent`
                                            : ``
                                    } flex items-center justify-center h-10 w-10 mr-4`}
                                >
                                    <div>
                                        {session.state === 'cancel' ? (
                                            <div className="font-bold text-xl">
                                                X
                                            </div>
                                        ) : session.state === 'planned' ? (
                                            <img
                                                src={upcomingIcon}
                                                alt="React Logo"
                                                className="h-10 w-10"
                                            />
                                        ) : (
                                            <>
                                                {' '}
                                                <img
                                                    src={checkIcon}
                                                    alt="React Logo"
                                                    className="h-4 w-4"
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="text-base flex-col w-[93%] flex">
                                    <div className=" flex items-center h-[50px] justify-between w-full">
                                        <div className="flex flex-col">
                                            <span className="font-semibold ">
                                                {formatDayDate(
                                                    new Date(session.date),
                                                )}
                                            </span>
                                            <div className="text-gray-500 text-sm">
                                                {' '}
                                                {session.isVideoSession
                                                    ? 'Video'
                                                    : 'Call'}
                                                {' - ' +
                                                    session.duration +
                                                    ' min'}
                                            </div>
                                        </div>
                                        <div
                                            className={`${
                                                session.state === 'cancel'
                                                    ? `bg-red-100 text-[#ff5353]`
                                                    : session.state === 'close'
                                                    ? `bg-[#f0f8fe] text-[#008aa6]`
                                                    : session.state ===
                                                      'planned'
                                                    ? `bg-transparent border-dashed border-2 border-gray-300`
                                                    : ``
                                            } rounded-full px-4 py-2 text-xs`}
                                        >
                                            {session.state === 'cancel'
                                                ? 'LATE CANCEL'
                                                : session.state === 'planned'
                                                ? 'UPCOMING'
                                                : 'DONE'}
                                        </div>
                                    </div>
                                    {hasNote && (
                                        <div className="border-t-[1px] pt-3 mt-2 flex gap-x-2 items-center border-gray-200">
                                            <span
                                                className="text-[#008aa6] font-[500] underline"
                                                onClick={() =>
                                                    openNote(session.note)
                                                }
                                            >
                                                Session Note
                                            </span>
                                            <button
                                                className={`button-status ${
                                                    session.note.completed
                                                        ? 'button-completed-status'
                                                        : 'button-incomplete-status'
                                                }`}
                                            >
                                                {session.note.completed
                                                    ? 'completed'
                                                    : 'incomplete'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
            {sessionsData?.length === 0 && !isLoading && (
                <div>No sessions yet.</div>
            )}
            <CoachNoteView
                keyIndex="2"
                mainTitle={currentItem?.main ? 'Update note' : 'Create note'}
                subTitle={`${coacheeName}'s notes`}
                item={currentItem}
                open={isCoachNoteViewOpen}
                close={openCoachNoteView}
                coacheeId={coacheeId}
                goBack={() => {}}
                onSave={handleSave}
            />
        </div>
    )
}
