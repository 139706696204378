import classNames from 'classnames'
import {
    ErrorMessage,
    Field,
    FieldProps,
    Form,
    Formik,
    FormikHelpers,
} from 'formik'
import { useCallback } from 'react'
import * as Yup from 'yup'
import { TextInput } from '../_components/inputs/text-input'
import SamaDropdownBox from '../_components/SamaDropdownBox'
import SamaMultiSelectInline from '../_components/SamaMultiSelectInline'
import { useStores } from '../utils/stores'
import './styles/coach-note-view.css'
import { Booking, BookingCancelDetails } from './types/Booking'

interface Props {
    mainTitle: string
    close: (isOpen: boolean) => void
    open: boolean
    session?: Booking
    handleFormSubmit?: any
    type?: string
    scheduleDetails?: any
}

const CoachCancelQuestionView: React.FC<Props> = ({
    mainTitle,
    close,
    open,
    session,
    handleFormSubmit,
    type,
    scheduleDetails,
}) => {
    const { coach } = useStores()

    const validationSchema = Yup.object({
        whoNeededChange: Yup.string().required('Required'),
        reasonForChange: Yup.string().required('Required'),
        customReason: Yup.string().when('reasonForChange', {
            is: (reasonForChange: string) => reasonForChange === 'Other',
            then: (schema) => schema.required('Please specify the reason'),
            otherwise: (schema) => schema,
        }),
        reachOutToCoachee: Yup.string().required('Required'),
    })

    const onSubmit = useCallback(
        (values: any, helpers: FormikHelpers<any>) => {
            const BookingCancelDetails: BookingCancelDetails = {
                reasonForChange: values.reasonForChange,
                customReason: values.customReason,
                whoNeededChange: values.whoNeededChange,
                reachOutToCoachee:
                    values.reachOutToCoachee === 'Yes' ? true : false,
            }

            helpers.setSubmitting(true)
            if (type === 'cancel') {
                if (session) {
                    coach
                        .cancelBooking(session, BookingCancelDetails)
                        .subscribe({
                            complete() {
                                helpers.setSubmitting(false)
                                close(false)
                            },
                        })
                }
            } else {
                handleFormSubmit(BookingCancelDetails)
                helpers.setSubmitting(false)
                close(false)
            }
        },
        [coach, close, session, type, scheduleDetails],
    )

    return (
        <Formik
            initialValues={{
                whoNeededChange: '',
                reasonForChange: '',
                customReason: '',
                reachOutToCoachee: '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                setFieldValue,
                values,
                isSubmitting,
                isValid,
                isValidating,
            }) => (
                <Form>
                    {open && (
                        <div className="goal-view-container">
                            <div
                                className="goal-view-box"
                                style={{ padding: '35px' }}
                            >
                                <div
                                    className="btn-close-solid"
                                    onClick={() => close(false)}
                                ></div>
                                <div>
                                    <div className="coach-question-box">
                                        <h2>{mainTitle}</h2>
                                    </div>

                                    <div className="questionBox">
                                        <label
                                            htmlFor={'whoNeededChange'}
                                            className="block text-[19px] mb-2 "
                                        >
                                            Who needed the change?
                                        </label>

                                        <Field name="whoNeededChange">
                                            {({ field }: FieldProps) => (
                                                <SamaMultiSelectInline
                                                    {...field}
                                                    initialValue={
                                                        values.whoNeededChange
                                                    }
                                                    value={
                                                        values.whoNeededChange
                                                    }
                                                    options={[
                                                        'Coach',
                                                        'Coachee',
                                                    ]}
                                                    onChange={(value: any) =>
                                                        setFieldValue(
                                                            'whoNeededChange',
                                                            value,
                                                        )
                                                    }
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="whoNeededChange"
                                            component="div"
                                            className="error"
                                        />
                                    </div>

                                    <div className="questionBox">
                                        <label
                                            htmlFor={'reasonForChange'}
                                            className="block text-[19px] mb-2 "
                                        >
                                            Reason for change or reschedule?
                                        </label>

                                        <Field name="reasonForChange">
                                            {({ field }: FieldProps) => (
                                                <SamaDropdownBox
                                                    {...field}
                                                    options={[
                                                        'Work conflict',
                                                        'Illness',
                                                        'Emergency',
                                                        'Technical problems',
                                                        'Other',
                                                    ]}
                                                    value={
                                                        values.reasonForChange
                                                    }
                                                    initialValue={
                                                        values.reasonForChange
                                                    }
                                                    emptyText="Select reason"
                                                    onChange={(value: any) =>
                                                        setFieldValue(
                                                            'reasonForChange',
                                                            value,
                                                        )
                                                    }
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="reasonForChange"
                                            component="div"
                                            className="error"
                                        />
                                        {values.reasonForChange === 'Other' && (
                                            <TextInput
                                                name="customReason"
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        'customReason',
                                                        e.target.value,
                                                    )
                                                }
                                                value={values.customReason}
                                                label={
                                                    'Other, Please specify reason'
                                                }
                                                placeholder="Please specify"
                                                className="mt-4"
                                                applyFocusStyle={true}
                                                disabled={isSubmitting}
                                            />
                                        )}
                                    </div>

                                    <div className="questionBox">
                                        <label
                                            htmlFor={'reachOutToCoachee'}
                                            className="block text-[19px] mb-2 "
                                        >
                                            Do you need us to reach out to the
                                            coachee for you?
                                        </label>
                                        <Field name="reachOutToCoachee">
                                            {({ field }: FieldProps) => (
                                                <SamaMultiSelectInline
                                                    {...field}
                                                    initialValue={
                                                        values.reachOutToCoachee
                                                    }
                                                    value={
                                                        values.reachOutToCoachee
                                                    }
                                                    options={['Yes', 'No']}
                                                    onChange={(value: any) =>
                                                        setFieldValue(
                                                            'reachOutToCoachee',
                                                            value,
                                                        )
                                                    }
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="coacheeReacheOut"
                                            component="div"
                                            className="error"
                                        />
                                    </div>
                                </div>

                                <div className="flex justify-between mt-6">
                                    <button
                                        type="submit"
                                        disabled={!isValid || isValidating}
                                        className={classNames(
                                            !isValid || isValidating
                                                ? 'orangeButton disabled'
                                                : 'orangeButton',
                                            'm-2',
                                        )}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </Form>
            )}
        </Formik>
    )
}

export default CoachCancelQuestionView
