import { useState, useEffect } from 'react'
import VideoChat from '../VideoChat/VideoChat'
import { Chat } from '../Chat/Chat'
import { TrackingService } from '../_services/tracking.service.js'
import { Booking } from './types/Booking'
import { CenterModal } from '../modals/center-modal'
import { classNames } from '../utils/misc/class-names'
import { useStores } from '../utils/stores'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'

const VideoPopup = ({ onCloseVideoPop }: { onCloseVideoPop: any }) => {
    const { coach } = useStores()
    const [isShowChat, setIsShowChat] = useState(false)
    const [booking, setBooking] = useState<Booking>()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search)
            const bookingId = query.get('bookingId')
            const booking = coach.bookings.filter(
                (booking) => booking._id === bookingId,
            )[0]
            if (booking) {
                setBooking(booking)

                const bookingDate = new Date(booking.date)
                const now = new Date()
                const isLate = now.getTime() - bookingDate.getTime() > 50000

                TrackingService.send('session_live_coaching_connected', {
                    is_late: isLate,
                    join_time: now.toISOString(),
                    is_video_session: booking.isVideoSession,
                    booking_id: booking._id,
                    booking_date: booking.date,
                    booking_duration: booking.duration,
                    coachee_id: booking.fromCoachee._id,
                    coachee_company_id: booking.fromCoachee?.company?._id,
                    coachee_company_label: booking.fromCoachee?.company?.label,
                })
            } else {
                navigate('/home')
            }
        }
    }, [])

    return (
        <>
            {booking && (
                <CenterModal
                    isOpen={true}
                    setIsOpen={() => {}}
                    className="w-[99vw] h-[98vh] bg-[#212529] inline-flex"
                    mainClassNames="flex"
                >
                    <main className={classNames('inline-flex')}>
                        {!isShowChat && (
                            <button
                                className="chat-bubble flex p-2 h-9"
                                onClick={() => {
                                    TrackingService.send('session_chat', {
                                        name: 'open',
                                        booking_id: booking._id,
                                    })
                                    setIsShowChat(true)
                                }}
                            >
                                <img src="/assets/images/chat-bubble.png" />
                            </button>
                        )}
                        {isShowChat && (
                            <div
                                className={classNames(
                                    'pr-[20px]',
                                    'inline-flex h-[97vh] overflow-y-auto',
                                )}
                            >
                                <Chat
                                    fromCoachee={booking.fromCoachee}
                                    videoView={true}
                                    closeChat={() => {
                                        TrackingService.send('session_chat', {
                                            name: 'close',
                                            booking_id: booking._id,
                                        })
                                        setIsShowChat(false)
                                    }}
                                />
                            </div>
                        )}
                        <VideoChat
                            booking={booking}
                            onCloseVideoPop={onCloseVideoPop}
                        />
                    </main>
                </CenterModal>
            )}
        </>
    )
}

export default VideoPopup
